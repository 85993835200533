import React from 'react';

function ShapefileLinks () {
  return (
    <section className="p-4 container">
      <p>
        In case you need them, here are the links to the shapefiles:
      </p>
      <br />
      <ul className="list-disc">
        <li>
          <a
            href="https://data.diegovalle.net/mapsmapas/scince_2020.tar.xz"
            rel="nofollow"
          >
            2020 Census Shapefiles (AGEBs, Manzanas, Municipios, Estados, etc)
          </a>
          &nbsp;
          <strong>1.4GB</strong>
          {' '}
          &mdash;
          {' '}
          <a href="https://gist.github.com/diegovalle/af936552e0998174313154a2ea9e92a9">
            Source
          </a>
          <a
            href="https://data.diegovalle.net/mapsmapas/encuesta_intercensal_2015.zip"
            rel="nofollow"
          >
            2015 Encuesta Intercensal Shapefiles of Mexico (AGEBs, Manzanas, Municipios, Estados, etc)
          </a>
          &nbsp;
          <strong>2.2GB</strong>
          {' '}
          &mdash;
          {' '}
          <a href="https://gist.github.com/diegovalle/aa3eef87c085d6ea034f">
            Source
          </a>
        </li>
        <li>
          <a
            href="http://data.diegovalle.net/mapsmapas/agebsymas.zip"
            rel="nofollow"
          >
            2010 Census Shapefiles of Mexico (AGEBs, Manzanas, Municipios, Estados, etc)
          </a>
          &nbsp;
          <strong>1.4GB</strong>
          {' '}
          &mdash; As described in this&nbsp;
          <a href="https://blog.diegovalle.net/2013/06/shapefiles-of-mexico-agebs-manzanas-etc.html">
            post
          </a>
        </li>
        <li>
          <a
            href="https://data.diegovalle.net/mapsmapas/eleccion_2010.zip"
            rel="nofollow"
          >
            Federal Electoral Shapefiles of Mexico (secciones, distritos and colonias)
          </a>
          &nbsp;
          <strong>1.2GB</strong>
          {' '}
          &mdash; As described in this&nbsp;
          <a href="https://blog.diegovalle.net/2013/02/download-shapefiles-of-mexico.html">
            post
          </a>
          . If you need the local or latest versions (without demographic data) you can download them from the
          {' '}
          <a href="http://cartografia.ife.org.mx/">INE</a>
        </li>
      </ul>
      <br />
      <hr />
      <br />
      <p className="py-4">
        Other places where you can download shapefiles of Mexico:
      </p>
      <ul className="list-disc">
        <li>
          <a href="https://www.inegi.org.mx/temas/mg/default.html#Descargas">
            INEGI
          </a>
          {' '}
          - Marco Geoestadistico
        </li>
        <li>
          <a href="https://datos.gob.mx/busca/dataset/ubicacion-de-codigos-postales-en-mexico">
            Correos de Mexico
          </a>
          {' '}
          - zip codes, and an
          {' '}
          <a href="https://drive.google.com/uc?export=download&id=0B-4W2dww7ELNQlRSTGVmT295REU">
            older version
          </a>
          {' '}
          that includes colonias
        </li>
        <li>
          <a href="https://cartografia.ife.org.mx/">INE</a>
          {' '}
          - the latest versions of secciones and distritos electorales
        </li>
      </ul>
      <p className="py-4">
        The shapefiles from INEGI also have a very permissive
        {' '}
        <a href="https://www.dof.gob.mx/nota_detalle.php?codigo=5374183&fecha=04/12/2014">
          license
        </a>
        {' '}
      </p>
    </section>
  );
}

export default ShapefileLinks;
